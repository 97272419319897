import { defineStore } from 'pinia';
import type { Faq, Question } from './types.ts';
import { useApi } from '~/shared/api/faq';

export const useFaqStore = defineStore('faq-store', {
  state: (): Faq => ({
    list: [] as Question[],
    questionId: 0,
  }),
  actions: {
    async getFAQ() {
      const { getFAQ } = useApi();
      const { success, data } = await getFAQ();
      if (success) {
        this.list = data.map((question: Question) => ({ ...question, hash: String(question.id) }));
      }
      return success;
    },
  },
});
