import type { Question } from '~/store/faq/types';

type ResponseData = {
  success: boolean
  data: Question[]
};

export function useApi() {
  const config = useRuntimeConfig();
  const apiUrl = import.meta.client
    ? config.public.apiUrlClient
    : config.public.apiUrlServer;

  const getFAQ = async (): Promise<ResponseData> => {
    try {
      const res: ResponseData = await $fetch(apiUrl + '/api/faq');
      return res;
    } catch (error: unknown) {
      console.error('Error occured when fetching FAQ.', error);
      return { success: false, data: [] };
    }
  };

  return {
    getFAQ,
  };
}
