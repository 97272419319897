<script setup lang="ts">
import { reactive, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { usePageStore } from '@/store/page/page';
import { useFaqStore } from '@/store/faq/faq';

const props = defineProps({
  answer: {
    type: String,
    default: '',
  },
  question: {
    type: String,
    default: '',
  },
  imageMobile: {
    type: String,
    default: '',
  },
  imageDesctop: {
    type: String,
    default: '',
  },
  answerBottom: {
    type: String,
    default: '',
  },
  itemId: {
    type: Number,
    default: null,
  },
  hash: {
    type: String,
    default: '',
  },
});

const state = reactive({
  toggleId: null as number | null,
  isShowDetail: false,
});

const route = useRoute();
const router = useRouter();

const pageStore = usePageStore();
const { isMobile } = storeToRefs(pageStore);

const faqStore = useFaqStore();
const { questionId } = storeToRefs(faqStore);

onMounted(() => {
  const hashRoute = route.hash.split('#');
  const currHash = hashRoute[hashRoute.length - 1];

  if (props.hash === currHash) {
    state.isShowDetail = true;
  }
});

const setActiveQuestion = (payload: number | null) => questionId.value = payload;

const openAccordion = (id: number, hash: string) => {
  if (id !== state.toggleId) {
    state.toggleId = id;
    if (route.path !== '/') {
      router.push({ hash: '#' + hash });
    }
    setActiveQuestion(null);
    state.isShowDetail = true;
  } else {
    state.toggleId = null;
    state.isShowDetail = false;
  }
};
</script>

<template>
  <div
    :id='hash'
    class='accordion-item'
  >
    <button
      class='accordion-item__title'
      :class="{
        'accordion-item__title--active': state.isShowDetail,
      }"
      type='button'
      @click='openAccordion(itemId, hash)'
    >
      <div v-html='question'></div>
    </button>

    <transition
      v-if='state.isShowDetail'
      name='accordion-body'
    >
      <div class='accordion-item__body'>
        <div
          class='accordion-item__body-inner'
          v-html='answer'
        ></div>

        <div
          v-if='imageDesctop.length'
          class='accordion-item__image'
        >
          <img
            v-show='!isMobile'
            :src='imageDesctop'
            alt='Таблица'
            class='d-block img-fluid'
          />
          <img
            v-show='isMobile'
            :src='imageMobile'
            alt='Таблица'
            class='d-block img-fluid'
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.accordion-item {
  color: inherit;
  border: 2px solid currentColor;
  border-radius: 1rem;
  width: 100%;
  scroll-margin-top: 4.375rem;

  @media (min-width: $breakpoint-lg) {
    border-radius: 3rem;
  }

  @media (min-width: $breakpoint-xl) {
    scroll-margin-top: 4.75rem;
    border-radius: 3.5rem;
  }
}

.accordion-item__title {
  width: 100%;
  margin: 0;
  padding: 1.25rem 0.5rem 1.25rem 1.25rem;
  border: none;
  background: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.1;
  color: currentColor;
  text-align: left;
  position: relative;
  transition: color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;

  @media (min-width: $breakpoint-lg) {
    border-radius: 3rem;
    font-size: 1.5rem;
    padding-left: 2rem;
    padding-right: 1rem;
  }

  @media (min-width: $breakpoint-xl) {
    font-size: 2rem;
    border-radius: 3.5rem;
  }

  &::after {
    content: '';
    width: 2.625rem;
    aspect-ratio: 1;
    mask-image: url('/assets/images/stack.svg#accordion-arrow');
    background-color: currentColor;
    transition: transform 0.3s ease;
    flex-grow: 0;
    flex-shrink: 0;

    @media (min-width: $breakpoint-lg) {
      width: 3rem;
    }
  }

  &:focus-visible {
    outline: 3px solid color-mix(in srgb, currentColor 50%, transparent);
    outline-offset: -0.25rem;
  }

  &--active::after {
    transform: rotate(90deg);
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.accordion-item__body {
  padding: 0 0.5rem 1.25rem 1.25rem;
  line-height: 1.1;

  @media (min-width: $breakpoint-lg) {
    font-size: 1.5rem;
    padding-left: 2rem;
    padding-right: 1rem;
  }
}

.accordion-item__body-inner {
  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:active,
    &:hover,
    &:focus {
      color: var(--color-primary);
    }

    @include focus-visible-default;
  }
}
</style>
